body {
  background: #FFF;
  color: #0E0E0E;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  -webkit-font-aliasing: antialiased;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

a {
  color: blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  line-height: 1.5em;
}

ul {
  line-height: 1.5em;
}

span.help {
  display: inline-block;
  color: #FFF;
  font-size: 10px;
  width: 16px;
  height: 16px;
  line-height: 18px;
  background-color: #000;
  border: 2px solid white;
  border-radius: 50px;
  font-weight: normal;
  margin-left: 5px;
  vertical-align: super;
}

.container {
  margin: 0 3rem 9rem;
  min-height: 100vh;
  padding: 20px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.narrow-content {
  margin: 0px auto;
}

.narrow-content img {
  max-width: 100%;
}

.about-content {
  padding: 1.5rem 3rem;
}

.about-content img {
  width: 50%;
}

header {
  display: flex;
  align-items: flex-end;
}

header h1 {
  flex: 1;
}

header h1 .alpine {
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  margin-left: 15px;
}

header ul {
  list-style: none;
  display: flex;
}

header li {
  padding: 5px 10px;
}


ul.members {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul.members li {
  margin: 0px 0px 40px 0px;
  padding: 0px;
  display: flex;
  align-items: flex-start;
  text-align: left;
}

ul.members li:nth-last-child(1) {
  margin-bottom: 0px;
}

ul.members img {
  width: 52px;
  height: 52px;
  border-radius: 24px; 
  display: block;
  margin-right: 15px;
}

ul.members a.handle {
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 8px;
  color: #FFF;
}

ul.members .date {
  color: #93999b;
  font-size: 10px;
}

ul.members .status {
  color: #93999b;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0px;
}

ul.members .actions {
  font-size: 14px;
  margin-top: 5px;
}

ul.members .meta {
  line-height: 1em;
  display: flex;
  flex-direction: column;
}

.list-stats {
  text-align: center;
  font-size: 24px;
  margin-bottom: 5px;
}

.lists {
  display: flex;
  margin-bottom: 2rem;
}

.list {
  flex: 1;
  padding: 0px 20px;
  margin: 30px;
  text-align: center;
}

.tcr-info {
  margin-top: 2rem;
  text-align: center;
  color: #93999b;
  font-size: 12px;
}

span.code {
  font-family: Monospace;
}

.spacer {
  flex: 1;
}
