
@import "../variables";
@import "../mixins";

.gradient-wrapper {
  $div-border: 0px;

  /* override .titlebar__title */
  :global .titlebar { 
    margin: 1rem 0 0.3rem;
    .titlebar__title {
      margin-top: 0;
      margin-bottom: 1rem;
      max-width: 100%;
      font-size: 22.4px;  
    }
  }

  z-index: 1;
  text-align:left; 
  margin: auto;
  position: relative;
  box-sizing: border-box;
  flex: 1 1 0%;
  margin:1rem 3rem 0 3rem;
  border-radius: 1.125rem;

  color: #FFF;
  background: rgb(0, 0, 0);
  background-clip: padding-box;
  border: none;

  > div {
    overflow-y: scroll;
    background-color: #000;
    border-radius: inherit;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$div-border;
    border-radius: inherit;
  }
}

.gradient-wrapper p, ul, li {
  line-height:1.2rem;
  font-size:1rem;
}

.gradient-wrapper h2 {
  font-weight:bolder;
  font-size:2rem;
}

.gradient-wrapper img {
  display:block;
  text-align:center;
}

h3 {
  margin: 3rem 0 3rem 0 !important;
  text-align:left !important;
}
