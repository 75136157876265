@import "./variables";
@import "./mixins";
@import "./fonts";
@import "./functions";
@import "./grid";

html {
  height: 100%;
  font-size: 100%; /* 16px by default; px value overrides user's browser settings */
}

/* body */

html,
body {
  color: white;
  background-color: black; // $color-darksmoke;
  font-weight: normal;
  font-family: $font-body;
}

body {
  margin: 0;
}

/* headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-header;
  font-weight: 500;
}

header {
  z-index: 9;
  position: relative;
}

main {
  flex: 1; /* sticky footer */
  z-index: 0; /* header nav needs to appear on top of <main> */
}

// REACT MOUNT POINT
#root {
  /* sticky footer */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* end sticky footer */
}

.titlebar {
  display: block;
  text-align: center;
  width: 100%;

  .titlebar__title {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      16px,
      22.4px
    ); /* min font-size 1rem, max 1.4rem */
    display: inline-block;
    letter-spacing: 2px;
    margin: 3.6rem 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    @media only screen and (min-width: em($bp-lg)) {
      max-width: 50%;
    }

    &:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%2375f86a' stroke-width='4' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
      background-position: 0 50%;
      background-repeat: repeat;
      background-size: 26px 82px;
      bottom: -1.5rem;
      content: "";
      height: 0.7rem;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .titlebar__action {
    background-color: $color-hotpink;
    border-radius: 1rem;
    border: none;
    color: white;
    display: block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    margin: 0 auto 2rem;
    padding: 1rem 2rem;

    @media only screen and (min-width: em($bp-xl)) {
      position: absolute;
      right: 0;
      top: 60px;
    }
  }
}

p {
  color: white;
  font-size: rem(18);
}

a {
  color: $color-babyblue;
}

/* layout */

.wrap {
  margin: 0 auto;
  display: flex;
  width: 100%;
}

.wrap-large {
  max-width: $width-wide;
}

/* layout: flex rows & columns */
// TODO add better rows columns
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

%column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.column {
  @extend %column;

  @include size-large-up {
    flex: 1;
  }
}

header {
  margin:0 3rem;
}

.container {
  margin: 0 0 0 0 !important;
  padding: 0 !important;
}

.column-30 {
  @extend %column;

  @include size-large-up {
    flex: 0.3;
  }
}

/* layout: divider */

hr.divider {
  /* reset */
  border: none;

  border-top: 1px solid $color-deepsmoke;
  margin: 1.5rem 0;
  width: 100%;
}

/* cards */

.card {
  background: #000;
  border-radius: 1rem;
  border: 1px solid #fff;
  margin: 0 0 2rem;
  padding: 2rem;
}

/* font */

.font-mono {
  font-family: $font-mono;
}

/* font-color */

.color-white {
  color: white;
}

.color-yellow {
  color: $color-sunny;
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: #{$color};
  }
}

/* alignment */

.text-center {
  text-align: center;
}

/* errors */

.error-message {
  color: $color-brightsalmon;
}

/* helpers */

.hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Avoid the IE 10-11 `min-height` bug. */
}

.image-wrap {
  position: relative;
  width: 100%;
}

nav {
  ul {
    li {
      display: inline-flex;
      margin: 0 0 0 2rem;

      a {
        color: white;
        text-decoration: none;
        text-transform: uppercase;

        &.active.members,
        &.members:hover {
          color: $color-babyblue;
        }
        &.active.proposals,
        &.proposals:hover {
          color: $color-babyblue;
        }
      }
    }

    margin-right: 2rem;
  }
}

.section-wrapper {
  flex: 1 1;
  margin: 0 0.75rem 9rem;

  @media only screen and (min-width: em($bp-lg)) {
    margin: 0 3rem 9rem;
  }
}

.why__tooltip {
  font-size: .75rem;
  float: right;
  opacity: .7;
  cursor: pointer;
  border-bottom: 2px dotted white;
  display: inline-flex;
  margin-top: 0;
  color: white;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
}

.none {
  text-align:left !important;
}

.titlebar {
    display: inline-block;
    letter-spacing: 2px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 0rem 0px;
}

.top-nav {
  position:absolute;
  top:3rem;
  right:0;
}

.top-nav a {
  color:#fff;
  margin: 0 0 0 1.8rem;
  font-size:1rem;
  letter-spacing: .09rem;
  display:block;
}

.top-nav a:hover {
  color:#6ff4fa;
  transform: rotate(-9deg);
  transition: transform 0.12s ease-in-out, color 0.25s ease-in-out;
}

.top-nav a.active {
  color:#6ff4fa !important;
}

.nav-button {
  position:absolute;
  top:1.8rem;
  right:0rem; 
  z-index:10 !important;
}

.logo {
  margin-top: 0.6rem;
    margin-left: 0.3rem;
}

.nav-button a {
  background-color: #e83adc;
    border-radius: 1rem;
    border: none;
    color: #fff;
    display: block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 auto 2rem;
    padding: 1rem 2rem;
}

span.help {
  width:14px !important;
  height:14px !important;
  text-align:center !important;
  line-height:14px !important;
}

h3 {
  font-size:1.4rem;
  font-weight:bold;
  margin:1rem 0 3rem 0 !important;
}

.lists-container {
  border:1px solid #fff;
  border-radius:1rem;
  padding-bottom:3rem;
  margin:2.2rem 0 0 0;
}

.list-stats {
  margin:3rem auto 1rem auto;
  font-size:1.2rem !important;
  line-height:1.6rem !important;
  width:80%;
}

.about-top {
  margin:2.2rem 0 0 0;
  padding:2rem 3rem;
  border:1px solid #fff;
  border-radius:1rem;
}

.about-img img {
  display:block;
  margin:3rem auto;
}

.tcr-info {
  margin-top: 1.8rem !important;
  margin-bottom: 1.8rem;
}

.about-content {
  padding:0 0 !important;
}

.footer_wrap {
  background-color:#131313;
  align-items: center;
  color: #bbb;
  display: flex;
  min-height: 2rem;
  padding: 1rem 1.5rem;
  position: relative;
  font-size: .8rem;
  margin:2.2rem 0 0 0;
  position:relative;
}

.footer_item {
  margin-right:2rem;
}

.footer_left a {
  text-decoration:underline !important;
}

.footer_right {
  position:absolute;
  right:1rem;
}

.footer_right svg {
  width:18px;
  color:#ccc;
  margin:0 1rem 0 0;
}

.social-media-wrapper {
  z-index: 99;
  position: relative;
  text-align: right;
  margin: 2rem;

  a {
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 0 1.2rem;

    &:hover {
      color: #ffffff;
    }
  }
  svg {
    width: 1.2rem;

    @include size-medium-down { 
      width: 1.5rem;
    }
  }
}

/**
 MOBILE STYLES
*/
@media screen and (max-width: 64em) { 
  .lists {
    flex-direction: column;
  }

  .list {
    padding: 0;
    margin: 20px;
  }

  .list-stats {
    font-size: 0.8rem !important;
    width: 86%;
  }

  .nav-button {
    position: absolute;
    top:30px;
  }

  .nav-button a {
    padding:0.6rem 0.9rem;
    font-size:0.6rem !important;
  }

  header li:not(:last-child) {
    display: none;
  }
}
