@import url('https://fonts.googleapis.com/css?family=Lexend+Exa&display=swap');

@font-face {
  font-family: 'Halfomania Regular';
  src: url('../fonts/Halfomania/Halfomania-Regular.ttf') format('truetype');
  src: url('../fonts/Halfomania/Halfomania-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Potra';
  src: url('../fonts/Potra/Potra.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

