@import "../variables";
@import "../mixins";
@import "../functions";

/* SHARED */

%font {
  color: white;
  display: block;
  line-height: 1.1;
  outline: none;
  font-family: $font-header--potra;
  font-weight: 200;
}

.logo {
  transform: rotate(-18deg);
  margin:1.9rem 0 0 0.3rem;
}

/* THE */

.the {
  @extend %font;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    32px
  ); /* min font-size 1.5rem, max 3rem */
  animation: theFlicker 1.6s infinite alternate;
  letter-spacing: 0.2rem;
}

.the--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    22px
  ); /* min font-size 2rem, max 3rem */
}

.the--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    32px,
    48px
  ); /* min font-size 2rem, max 3rem */
}

/* LAO */

.lao {
  @extend %font;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    64px,
    96px
  ); /* min font-size 4rem, max 6rem */

  animation: laoFlicker 1.8s infinite alternate;
  font-size: 6em;
  letter-spacing: 0.1rem;
}

.lao--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    34px,
    56px
  ); /* min font-size 6rem, max 9rem */
}

.lao--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    96px,
    144px
  ); /* min font-size 6rem, max 9rem */
}

/* ANIMATION FLICKER */

@keyframes theFlicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 1rem $color-brightlime, 0 0 2rem $color-brightlime,
      0 0 3rem $color-brightlime, 0 0 4rem $color-brightlime,
      0 0 5rem $color-brightlime;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}

@keyframes laoFlicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 2rem $color-hotpink, 0 0 4rem $color-hotpink, 0 0 6rem $color-hotpink,
      0 0 8rem $color-hotpink, 0 0 10rem $color-hotpink;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}
